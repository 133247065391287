.App {
    align-items: center;
    bottom: 0;
    color: #555;
    display: flex;
    font-family: "Nunito Sans", sans-serif;
    left: 0;
    justify-content: center;
    right: 0;
    position: absolute;
    text-align: center;
    top: 0;
}

h2 {
    font-weight: 400;
}

.header {
    margin: 30px;
    padding: 48px;
}
